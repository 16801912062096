<template>
    <div class="page-wrapper">
        <div class="page-container">
            <Header/>
            <main>
                <slot/>
            </main>
			<PreFooter/>
            <Footer/>
        </div>
    </div>
</template>

<script>
export default {
	name: 'default',
};
</script>

<style lang="scss" scoped>
main {
	min-height:calc(100vh - 400px);
}
</style>
