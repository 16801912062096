<template>
    <div class="footer">
        <div class="footer__main-hp">
            <div v-for="category in store.prefooter" class="footer__link-col">
                <h3 class="headline-4">{{category.category_name}}</h3>

                <div class="footer__link-block op-045">
                    <a v-for="link in category.links"
                        :href="link.url"
                       :aria-label="link.name"
                       class="footer__link body-1">{{link.name}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useStore} from '~/stores/store';

export default {
	name: 'PreFooter',
    setup() {
        const store = useStore();

        return {store}
    },
	methods: {
		scrollToTop() {
			this.scrollToSmoothly(0, 1000);
		},
		scrollToSmoothly(pos, time) {
			var currentPos = window.pageYOffset;
			var start = null;
			if (time == null) time = 500;
			(pos = +pos), (time = +time);
			window.requestAnimationFrame(function step(currentTime) {
				start = !start ? currentTime : start;
				var progress = currentTime - start;
				if (currentPos < pos) {
					window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
				} else {
					window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
				}
				if (progress < time) {
					window.requestAnimationFrame(step);
				} else {
					window.scrollTo(0, pos);
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.footer {
    margin-top: auto;

    &__main {
        display: flex;
        gap: 2.4rem;
        justify-content: space-between;
        padding: 4rem 0 3.2rem;
        width: 100%;
        @media (max-width: 767px) {
            flex-direction: column;
        }

        &-hp{
            display: flex;
            gap: 2rem;
            justify-content: space-between;
            padding: 4.2rem 0;
            width: 100%;
            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
            @media (max-width: 550px) {
                max-width: 310px;
                justify-content: flex-start;
                gap: 2rem 4rem;
                margin: 0 auto;
            }
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        max-width: 32.3rem;
    }

    &__logo {
        width: 7.3rem;
        height: 7.3rem;
        &-line{
            height: 4.4rem;
            @media (max-width: 550px) {
                height: 3.6rem;
            }
        }
    }

    &__link {
        color: $white;
        line-height: 2;

        &:hover {
            color: $white;
            text-decoration: underline;
        }

        &-list {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            max-width: 62.6rem;
            gap: 2.4rem;
            @media (max-width: 389px) {
                flex-direction: column;
                gap: 1.6rem;
            }
        }

        &-col {
            display: flex;
            flex-direction: column;
            gap: 1.8rem;
        }

        &-block {
            display: flex;
            flex-direction: column;
            gap: .4rem;
        }
    }

    &__row {
        display: flex;
        gap: 2.4rem;
        @media (max-width: 767px) {
            flex-direction: column;
            gap: 1.6rem;
        }
        &-hp{
            display: flex;
            gap: 3.2rem;
            @media (max-width: 767px) {
                gap: 1.6rem;
                flex-wrap: wrap;
            }
            @media (max-width: 389px) {
                max-width: 230px;
                justify-content: center;
            }
        }
    }
}
</style>
